class BlurbCard extends BATComponent {
	/* Lifecycle event callbacks */
	beforeLoad() {
		// console.log('BlurbCard before load');
	}

	afterLoad() {
		// console.log('BlurbCard After load');
		this.$el = $(this);
	}

	beforeUpdate() {
		// console.log('BlurbCard before update');
	}

	afterUpdate() {
		// console.log('BlurbCard after update');
	}

	unload() {
		// console.log('BlurbCard after unload');
	}
}

!customElements.get('bat-card-blurb') &&
	customElements.define('bat-card-blurb', BlurbCard);
